/**
 * Component was generated by svgr (https://github.com/smooth-code/svgr)
 * Read our README.md for details
 */

/* eslint-disable */
import * as React from 'react';

interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
  viewBox?: string;
}

export const CoinSmall = (props: IconProps) => (
  <svg
    className={`a-icon a-icon--coin-smaller ${props.className || ''}`}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path d="M0 0L24 0 24 24 0 24z" />
      <path
        fill="#333"
        d="M12 5c-3.864 0-7 3.136-7 7s3.136 7 7 7 7-3.136 7-7-3.136-7-7-7zm.987 11.263v.406c0 .511-.42.931-.931.931h-.007c-.511 0-.931-.42-.931-.931v-.42c-.931-.196-1.757-.707-2.107-1.568-.161-.385.14-.812.56-.812h.168c.259 0 .469.175.567.42.203.525.735.889 1.757.889 1.372 0 1.68-.686 1.68-1.113 0-.581-.308-1.127-1.869-1.498-1.736-.42-2.926-1.134-2.926-2.569 0-1.204.973-1.988 2.177-2.247v-.42c0-.511.42-.931.931-.931h.007c.511 0 .931.42.931.931v.434c.966.238 1.575.84 1.841 1.582.14.385-.154.791-.567.791h-.182c-.259 0-.469-.182-.539-.434-.161-.532-.602-.875-1.484-.875-1.05 0-1.68.476-1.68 1.148 0 .588.455.973 1.869 1.337s2.926.973 2.926 2.737c-.014 1.281-.973 1.981-2.191 2.212z"
      />
    </g>
  </svg>
);
