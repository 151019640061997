/**
 * Component was generated by svgr (https://github.com/smooth-code/svgr)
 * Read our README.md for details
 */

/* eslint-disable */
import * as React from 'react';

interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
  viewBox?: string;
}

export const AddCircle = (props: IconProps) => (
  <svg
    className={`a-icon a-icon--add-circle ${props.className || ''}`}
    height="1.5em"
    viewBox="0 0 24 24"
    width="1.5em"
    {...props}
  >
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm4 11h-3v3c0 .55-.45 1-1 1s-1-.45-1-1v-3H8c-.55 0-1-.45-1-1s.45-1 1-1h3V8c0-.55.45-1 1-1s1 .45 1 1v3h3c.55 0 1 .45 1 1s-.45 1-1 1z" />
  </svg>
);

AddCircle.displayName = 'AddCircle';

export default AddCircle;
