/**
 * Component was generated by svgr (https://github.com/smooth-code/svgr)
 * Read our README.md for details
 */

/* eslint-disable */
import * as React from 'react';

interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
  viewBox?: string;
}

export const Save = (props: IconProps) => (
  <svg
    className={`a-icon a-icon--saveicon ${props.className || ''}`}
    id="Ebene_1"
    data-name="Ebene 1"
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <defs>
      <style>{'.cls-1{fill:none;}'}</style>
    </defs>
    <title>{'save'}</title>
    <g id="fs24-mvp-m-results-v1-select3">
      <g id="Group-2">
        <g id="Stacked-Group">
          <g id="round-save-24px">
            <polygon id="Path" className="cls-1" points="0 0 24 0 24 24 0 24 0 0" />
            <path
              id="Shape"
              className="cls-2"
              d="M14.59.59A2,2,0,0,0,13.17,0H2A2,2,0,0,0,0,2V16a2,2,0,0,0,2,2H16a2,2,0,0,0,2-2V4.83a2,2,0,0,0-.59-1.41ZM9,16a3,3,0,1,1,3-3A3,3,0,0,1,9,16ZM10,6H4A2,2,0,0,1,4,2h6a2,2,0,0,1,0,4Z"
              transform="translate(3 3)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

Save.displayName = 'Save';

export default Save;
