/**
 * Component was generated by svgr (https://github.com/smooth-code/svgr)
 * Read our README.md for details
 */

/* eslint-disable */
import * as React from 'react';

interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
  viewBox?: string;
}

export const DogPaw = (props: IconProps) => (
  <svg
    className={`a-icon a-icon--dog-paw ${props.className || ''}`}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path d="M0 0L24 0 24 24 0 24z" />
      <path
        fill="#333"
        d="M12 10.5c.26 0 .53 0 .78.04.11.02.22.05.33.09.7.24 1.29.78 1.75 1.32.88 1.02 1.61 1.89 2.48 2.91 1.31 1.31 2.92 2.76 2.61 4.8-.29 1.01-1.02 2.03-2.33 2.32-.73.14-3.06-.44-5.54-.44h-.18c-2.48 0-4.81.59-5.54.44-1.31-.29-2.04-1.3-2.33-2.32-.3-2.03 1.31-3.48 2.62-4.79.88-1.02 1.61-1.89 2.48-2.91.47-.54 1.05-1.08 1.75-1.32.11-.04.22-.07.33-.09.26-.05.53-.05.79-.05zM4.5 7C5.88 7 7 8.12 7 9.5S5.88 12 4.5 12 2 10.88 2 9.5 3.12 7 4.5 7zm15 0C20.88 7 22 8.12 22 9.5S20.88 12 19.5 12 17 10.88 17 9.5 18.12 7 19.5 7zM9 3c1.38 0 2.5 1.12 2.5 2.5S10.38 8 9 8 6.5 6.88 6.5 5.5 7.62 3 9 3zm6 0c1.38 0 2.5 1.12 2.5 2.5S16.38 8 15 8s-2.5-1.12-2.5-2.5S13.62 3 15 3z"
      />
    </g>
  </svg>
);
