/**
 * Component was generated by svgr (https://github.com/smooth-code/svgr)
 * Read our README.md for details
 */

/* eslint-disable */
import * as React from 'react';

interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
  viewBox?: string;
}

export const Fire = (props: IconProps) => (
  <svg
    className={`a-icon a-icon--fire ${props.className || ''}`}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path d="M0 0H24V24H0z" />
      <path d="M0 0H24V24H0z" />
      <g fill="#333">
        <path
          d="M18 0H2C.9 0 0 .9 0 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2zm0 17c0 .55-.45 1-1 1h-1v-1c0-.55-.45-1-1-1h-1.15c.71-.85 1.15-1.89 1.15-3 0-1.89-1.09-2.84-1.85-3.36-1.86-1.27-2.23-2.78-2.25-3.72-.01-.4-.43-.63-.77-.43-5.8 3.43-5.15 7-5.13 7.51.03.96.49 2.07 1.24 3H5c-.55 0-1 .45-1 1v1H3c-.55 0-1-.45-1-1V3c0-.55.45-1 1-1h14c.55 0 1 .45 1 1v14zm-6.8-5.26c-.08-.46-.07-.85.08-1.28.54 1.21 2.15 1.64 1.98 3.18-.19 1.69-2.11 2.37-3.39 1.32.76-.24 1.4-1.04 1.53-1.63.12-.55-.11-1.04-.2-1.59z"
          transform="translate(2 2)"
        />
      </g>
    </g>
  </svg>
);
