/**
 * Component was generated by svgr (https://github.com/smooth-code/svgr)
 * Read our README.md for details
 */

/* eslint-disable */
import * as React from 'react';

interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
  viewBox?: string;
}

export const Close = (props: IconProps) => (
  <svg
    className={`a-icon a-icon--close ${props.className || ''}`}
    width="1.5em"
    height="1.5em"
    viewBox="0 0 20 20"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path d="M0 0L20 0 20 20 0 20z" />
      <path
        fill="#1175D9"
        d="M15.25 4.758c-.325-.325-.85-.325-1.175 0L10 8.825 5.925 4.75c-.325-.325-.85-.325-1.175 0-.325.325-.325.85 0 1.175L8.825 10 4.75 14.075c-.325.325-.325.85 0 1.175.325.325.85.325 1.175 0L10 11.175l4.075 4.075c.325.325.85.325 1.175 0 .325-.325.325-.85 0-1.175L11.175 10l4.075-4.075c.317-.317.317-.85 0-1.167z"
      />
    </g>
  </svg>
);

Close.displayName = 'Close';

export default Close;
