/**
 * Component was generated by svgr (https://github.com/smooth-code/svgr)
 * Read our README.md for details
 */

/* eslint-disable */
import * as React from 'react';

interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
  viewBox?: string;
}

export const Diamond = (props: IconProps) => (
  <svg
    className={`a-icon a-icon--diamond ${props.className || ''}`}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path d="M0 0H24V24H0z" />
      <path
        fill="#333"
        d="M17.404 3c.336 0 .663.08.955.23l-.085-.036c.038.014.074.03.108.049.27.142.51.344.697.595l3.002 4.007c.582.776.555 1.85-.065 2.593l-8.41 10.178c-.08.095-.166.182-.261.261-.354.297-.778.456-1.207.484l-.064.003c-.044.004-.09.005-.135 0l-.02-.001c-.57-.022-1.13-.277-1.526-.75L1.987 10.44c-.286-.344-.446-.757-.479-1.179-.005-.037-.008-.077-.008-.117l.002-.052c.001-.438.14-.877.418-1.248l3.005-4.007c.188-.251.427-.453.697-.596l.04-.02C5.95 3.078 6.27 3 6.6 3zm-2.329 6.887H8.927l3.074 8.544 3.074-8.544zm-7.727 0H3.456l6.893 8.341-3.001-8.341zm13.196 0h-3.89l-3 8.338 6.89-8.338zM5.693 5.288L3.358 8.402h3.455l-1.12-3.114zm12.616 0l-1.12 3.114h3.453l-2.333-3.114zm-6.308-.308L9.435 8.402h5.133L12.001 4.98zm-1.485-.495H6.983l1.145 3.183 2.388-3.183zm6.503 0h-3.532l2.387 3.183 1.145-3.183z"
      />
    </g>
  </svg>
);
