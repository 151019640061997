/**
 * Component was generated by svgr (https://github.com/smooth-code/svgr)
 * Read our README.md for details
 */

/* eslint-disable */
import * as React from 'react';

interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
  viewBox?: string;
}

export const ChevronDownBlue = (props: IconProps) => (
  <svg
    className={`a-icon a-icon--chevron-down-blue ${props.className || ''}`}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path d="M24 24L0 24 0 0 24 0z" opacity={0.87} />
      <path
        fill="#1175D9"
        d="M15.88 9.29L12 13.17 8.12 9.29c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41l4.59 4.59c.39.39 1.02.39 1.41 0l4.59-4.59c.39-.39.39-1.02 0-1.41-.39-.38-1.03-.39-1.42 0z"
      />
    </g>
  </svg>
);

ChevronDownBlue.displayName = 'ChevronDownBlue';

export default ChevronDownBlue;
