/**
 * Component was generated by svgr (https://github.com/smooth-code/svgr)
 * Read our README.md for details
 */

/* eslint-disable */
import * as React from 'react';

interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
  viewBox?: string;
}

export const MoneyBag = (props: IconProps) => (
  <svg
    className={`a-icon a-icon--money ${props.className || ''}`}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path d="M0 0L24 0 24 24 0 24z" />
      <path
        fill="#333"
        d="M14.325 7.655c.084 0 .308.103.379.152.838.589 1.515 1.23 2.189 2.016 1.576 1.841 2.829 3.903 3.728 6.196.406 1.034.512 2.11.225 3.204-.436 1.662-1.701 2.714-3.335 2.766-.588.02-1.177.007-1.766.007H6.721c-.908.001-1.737-.245-2.438-.876-.746-.672-1.128-1.548-1.242-2.563-.106-.932.048-1.828.4-2.682 1.064-2.583 2.501-4.904 4.434-6.86.462-.466.928-.79 1.432-1.209.065-.053.294-.151.378-.151 1.59-.005 3.05-.005 4.64 0zm-2.31 1.695c-.518 0-.938.42-.938.931v.42C9.873 10.96 8.9 11.744 8.9 12.948c0 1.435 1.19 2.149 2.926 2.569 1.561.371 1.869.917 1.869 1.498 0 .427-.308 1.113-1.68 1.113-1.022 0-1.554-.364-1.757-.889-.098-.245-.308-.42-.567-.42h-.168c-.42 0-.721.427-.56.812.35.861 1.176 1.372 2.107 1.568v.42c0 .511.42.931.931.931.518 0 .938-.42.938-.931v-.406c1.218-.231 2.177-.931 2.191-2.212 0-1.764-1.512-2.373-2.926-2.737-1.414-.364-1.869-.749-1.869-1.337 0-.672.63-1.148 1.68-1.148.882 0 1.323.343 1.484.875.07.252.28.434.539.434h.182c.413 0 .707-.406.567-.791-.266-.742-.875-1.344-1.841-1.582v-.434c0-.511-.42-.931-.931-.931zm3.83-7.229c.174.048.355.071.526.126.328.105.477.359.38.687-.06.203-.151.405-.268.581-.57.856-1.153 1.705-1.739 2.551-.037.054-.14.09-.211.09-.837.007-1.674.005-2.512.005-.808 0-1.617-.003-2.426.002-.13.001-.215-.033-.292-.147-.467-.68-.95-1.35-1.411-2.035-.18-.266-.341-.55-.466-.844-.204-.485-.054-.818.46-.914.608-.114 1.163-.234 1.78-.2.438.025.631.146.902.255.68.274 1.385.393 2.109.3.382-.05.778-.14 1.123-.305.222-.106.512-.18.734-.223.453-.089.841-.056 1.31.071z"
      />
    </g>
  </svg>
);
